function transformErrors(payload) {
	const errors = payload?.data?.errors;

	if (errors) {
		payload.data.errors = errors.map((error) => {
			return {
				...error,
				title: error.detail,
				source: {
					pointer: (error.source && error.source.pointer) || '_error',
				},
			};
		});
	}

	return payload;
}

export default {
	name: 'error-transform',
	error: (payload) => {
		return transformErrors(payload);
	},
};
