import JsonApi from 'devour-client';
import snakeRequestMiddleware from './middleware/snake-request';
import * as models from './models';
// import authRequestMiddleware from './middleware/auth-request';
import camelizeResponseMiddleware from './middleware/camelize-response';
import errorTransformMiddleware from './middleware/error-transform';
import localizationMiddleware from './middleware/localization-middleware';
import requestWithCredentialsMiddleware from './middleware/with-credentials-middleware';

const isServer = typeof window === 'undefined';

export function createClient({ locale, cookie = null }) {
	const client = new JsonApi({
		apiUrl: isServer ? process.env.API_URL : process.env.NEXT_PUBLIC_API_URL,
	});

	if (cookie) {
		//@ts-ignore
		client.headers['Cookie'] = cookie;
	}

	client.insertMiddlewareBefore('axios-request', localizationMiddleware(locale));
	client.insertMiddlewareBefore('axios-request', requestWithCredentialsMiddleware);
	client.insertMiddlewareBefore('axios-request', snakeRequestMiddleware);
	client.insertMiddlewareBefore('snake-request', localizationMiddleware(locale));
	// client.insertMiddlewareBefore('snake-request', authRequestMiddleware);
	client.insertMiddlewareBefore('response', camelizeResponseMiddleware);
	client.insertMiddlewareBefore('errors', errorTransformMiddleware);

	Object.keys(models).forEach((key) => {
		models[key].default(client);
	});

	return client;
}
