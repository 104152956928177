import DevourClient from 'devour-client';

export const TYPE = 'museum/translation';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			translations: {
				city: '',
				country: '',
				email: '',
				name: '',
				object: '',
				phone: '',
				relationshipDuration: '',
				story: '',
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
