export default {
	titleTemplate: 'Brokenships | %s',
	title: 'Brokenships',
	description: 'Where memories of broken hearts live.',
	openGraph: {
		title: 'Brokenships',
		description: 'Where memories of broken hearts live.',
		images: [
			{
				url: `${process.env.NEXT_PUBLIC_SITE_URL}/images/facebook-share-default.png`,
				width: 818,
				height: 520,
				alt: 'Brokenships',
			},
			{
				url: `${process.env.NEXT_PUBLIC_SITE_URL}/images/twitter-share-default.png`,
				width: 818,
				height: 520,
				alt: 'Brokenships',
			},
		],
		type: 'website',
		site_name: 'Brokenships',
	},

	twitter: {
		cardType: 'summary_large_image',
		site: '@Brokenships',
		handle: '@Brokenships',
	},
};
