import DevourClient from 'devour-client';

export const TYPE = 'user/reset_password';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			email: '',
		},
		{
			type: TYPE,
		}
	);

	return client;
}
