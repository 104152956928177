export default (locale) => ({
	name: 'auth-request',
	req: (payload) => {
		Object.assign(payload.req.headers, {
			'Accept-Language': locale,
		});

		return payload;
	},
});
