import DevourClient from 'devour-client';

export const TYPE = 'user/favorite';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			faved: {
				jsonApi: 'hasOne',
			},
			user: {
				jsonApi: 'hasOne',
				type: 'user',
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
