import DevourClient from 'devour-client';

export const TYPE = 'museum';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			title: '',
			location: '',
			geolocation: [],
			createdAt: '',
			updatedAt: '',
			link: '',
			image: {},
			category: '',
			status: '',
			availableFrom: '',
			availableUntil: '',
			openForDonation: '',
			city: '',
			callForDonationText: '',
			description: '',
			donationType: '',
			favoritesCount: '',
			favoriter: {
				jsonApi: 'hasOne',
				type: 'user/favorite',
			},
			translations: {
				jsonApi: 'hasOne',
				type: 'museum/translation',
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
