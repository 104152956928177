import DevourClient from 'devour-client';

export const TYPE = 'user/passwords';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			resetPasswordToken: '',
			password: '',
		},
		{
			type: TYPE,
		}
	);

	return client;
}
