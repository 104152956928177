import DevourClient from 'devour-client';

export const TYPE = 'contact/message';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			senderEmail: '',
			receiverEmail: '',
			content: '',
		},
		{
			type: TYPE,
		}
	);

	return client;
}
