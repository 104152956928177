import DevourClient from 'devour-client';

export const TYPE = 'collection/item';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			title: '',
			content: '',
			relevantFrom: '',
			relevantUntil: '',
			dateFilled: '',
			published: '',
			location: '',
			orientation: '',
			favoritesCount: '',
			commentsCount: '',
			createdAt: '',
			updatedAt: '',
			type: '',
			slug: '',
			image: {},
			imageMeta: {},
			comments: {
				jsonApi: 'hasMany',
				type: 'comment',
			},
			favorites: {
				jsonApi: 'hasMany',
				type: 'favorite',
			},
			favoriter: {
				jsonApi: 'hasOne',
				type: 'user/favorite',
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
