import DevourClient from 'devour-client';

export const TYPE = 'post';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			title: '',
			content: '',
			relevantFrom: '',
			relevantUntil: '',
			dateFilled: '',
			postedAt: '',
			unlocksAt: '',
			pinnedAt: '',
			approved: '',
			published: '',
			location: '',
			geolocation: '',
			longitude: '',
			latitude: '',
			initials: '',
			orientation: '',
			favoritesCount: '',
			commentsCount: '',
			type: '',
			slug: '',
			createdAt: '',
			updatedAt: '',
			image: {},
			imageMeta: {},
			videoUrl: '',
			username: '',
			user: {
				jsonApi: 'hasOne',
				type: 'user',
			},
			comments: {
				jsonApi: 'hasMany',
				type: 'comment',
			},
			favorites: {
				jsonApi: 'hasMany',
				type: 'user/favorite',
			},
			favoriter: {
				jsonApi: 'hasOne',
				type: 'user/favorite',
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
