import DevourClient from 'devour-client';

export const TYPE = 'user/session';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			email: '',
			firstName: '',
			lastName: '',
			initials: '',
			password: '',
			token: '',
			comments: {
				jsonApi: 'hasMany',
				type: 'comments',
			},
			favorites: {
				jsonApi: 'hasMany',
				type: 'favorites',
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
