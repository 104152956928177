import DevourClient from 'devour-client';
import { TYPE as MUSEUM_TYPE } from './museum';

export const TYPE = 'museum/donation';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			name: '',
			age: '',
			gender: '',
			nationality: '',
			country: '',
			city: '',
			email: '',
			phone: '',
			object: '',
			relationshipDuration: '',
			timeSinceBreakUp: '',
			story: '',
			inRelationship: '',
			currentRelationshipDuration: '',
			digitalPossessionTitle: '',
			digitalPossessionDate: '',
			digitalPossessionStory: '',
			attachment: {},
			museum: {
				jsonApi: 'hasOne',
				type: MUSEUM_TYPE,
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
