import { FC, ReactNode, useMemo } from 'react';

import { createContext } from '@chakra-ui/react-context';
import { useRouter } from 'next/router';
import { createClient } from '@/jsonapi/createClient';
import type DevourClient from 'devour-client';

export const [ClientProvider, useClient, ClientContext] = createContext<DevourClient>({
	name: 'ClientContext',
	hookName: 'useClient',
});

interface IAppProviderProps {
	children: ReactNode;
}

export const AppProvider: FC<IAppProviderProps> = ({ children }) => {
	const { locale } = useRouter();
	const client = useMemo(() => {
		return createClient({ locale });
	}, [locale]);

	return <ClientProvider value={client}>{children}</ClientProvider>;
};
