import normalize from 'normalize-object';

export default {
	name: 'snake-request',
	req: (payload) => {
		payload.req.data = normalize(payload.req.data, 'snake');

		return payload;
	},
};
