import DevourClient from 'devour-client';

export const TYPE = 'user';

export default function (client: DevourClient) {
	client.define(
		TYPE,
		{
			email: '',
			firstName: '',
			lastName: '',
			initials: '',
			password: '',
			token: '',
			stories: {
				jsonApi: 'hasMany',
				type: 'user/story',
			},
			favorites: {
				jsonApi: 'hasMany',
				type: 'user/favorite',
			},
		},
		{
			type: TYPE,
		}
	);

	return client;
}
