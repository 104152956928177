import normalize from 'normalize-object';

export default {
	name: 'camelize-response',
	res: (payload) => {
		payload.res.data = normalize(payload.res.data, 'camel');

		return payload;
	},
};
